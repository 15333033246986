export const servizi = 
    {
        "yatequiero.com" : {
            payment_company:"gankomedia",
           
        },
        "localhost":{
            
            payment_company:"gankomedia",

        },
       
        "placerbonito.com" : {
           
            payment_company:"gankomedia",
          
        },
        "jammydate.com":{
           
            payment_company:"gankomedia",
            
        },
        "looviing.com":{
           
            payment_company:"gankomedia",
           
        }
    }