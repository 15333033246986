// ResponsiveMenu.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/menu.css'; // Importa il CSS per lo stile

const ResponsiveMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="responsive-menu">
            <div className="menu-toggle" onClick={toggleMenu}>
                &#9776; {/* Icona del menu */}
            </div>
            <ul className={`menu-items ${isOpen ? 'open' : ''}`}>
              
                <li >
                    <Link to="/users" onClick={toggleMenu}>Utenti</Link>
                </li>
                <li>
                    <Link to="/pay_rules" onClick={toggleMenu}>Pay Rules</Link>
                </li>
            </ul>
        </nav>
    );
};

export default ResponsiveMenu;
