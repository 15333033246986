import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider, useAuthValue } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import Login from './Login';
import Menu from './components/menu';

import Profile from './Profile';
import PaymentRoute from './components/cc_pay_rules';
import Users from './components/user/users';
import UserDetail from './components/user/UserDetail';

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

function AppContent() {
  const { setCurrentUser, setToken } = useAuthValue();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      
      setCurrentUser({ token });
      setToken(token);
    }
  }, [setCurrentUser, setToken]);

  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route
         exact
         path='/'
         element={
           <PrivateRoute>
             <Menu />
             <Users />
           </PrivateRoute>
         }
      />
      <Route
        exact
        path='/profilo'
        element={
          <PrivateRoute>
            <Menu />
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path='/users'
        element={
          <PrivateRoute>
            <Menu />
            <Users />
          </PrivateRoute>
        }
      />
     <Route path="/user/:clickid" element={
         <PrivateRoute>
         <Menu />
         <UserDetail />
       </PrivateRoute>} />
      <Route
        exact
        path='/pay_rules'
        element={
          <PrivateRoute>
            <Menu />
            <PaymentRoute />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;