import React from 'react';
import functions from './functions/functions';


const ChatLogDetails = ({ el, expandedChatLogIndex }) => {

    const chiaviDaTenere = [ 'time','msg', 'type', 'screenName', 'letto']
    const filteredJson = functions.filtraChiavi(el, chiaviDaTenere)

    return (
       
                <ul>
                    {expandedChatLogIndex === true && (
                        <>
                           
                           
                                <li key={el._id}>
                                    <div className="log-details" style={{ backgroundColor: el.type === "personal" ? 'rgb(247, 251, 227)' : 'rgb(251, 233, 227)' }}>
                                        <p><h3>{functions.formattaDataOra(el.time)}</h3> <span style={{ maxWidth: "100%" }}>{functions.formatJsonToHtml(filteredJson)}</span></p>
                                    </div>
                                </li>
                           
                        </>
                    )}
                </ul>
           
    );
};

export default ChatLogDetails;