import React from 'react';
import '../../css/popup.css';

const Popup = ({ message, buttons }) => {
    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <p>{message}</p>
                <div className="popup-buttons">
                    {buttons.map((button, index) => (
                        <button key={index} onClick={button.action}>
                            {button.label}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Popup;