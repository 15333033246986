import React, { useContext, useState, useEffect } from 'react';

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);

  const [customer, setCustomer] = useState(null);

  const [user, setUser] = useState({
      email: '',
      roles: [] // Aggiungi i ruoli dell'utente qui
  });

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
      setCurrentUser({ token: storedToken });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser, token, setToken,  user, setUser, customer, setCustomer}}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthValue() {
  return useContext(AuthContext);
}