


const fetch_url = process.env.REACT_APP_BACKEND;
const config = require('../../_helpers/config_servizi');

const fetchUsers = async  (clickid,token) => {
    try {
        const response = await fetch(`${fetch_url}/getUser/${clickid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        return data
        
    } catch (error) {
        console.error('Errore nel recupero dell\'utente:', error);
    }
};

const fetchEmailLog = async (clickid,token) => {
    try {
        const response = await fetch(`${fetch_url}/getEmailLog/${clickid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        return data
    } catch (error) {
        console.error('Errore nel recupero dell\'utente:', error);
    }
};



const fetchPayLog = async (clickid,token) => {
    try {
        const response = await fetch(`${fetch_url}/getPayLog/${clickid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
       
        

        return data
    } catch (error) {
        console.error('Errore nel recupero dell\'utente:', error);
    }
};


const fetchChatLog = async (clickid,token) => {
    try {
        const response = await fetch(`${fetch_url}/getChatLog/${clickid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();

       
        return data
       
    } catch (error) {
        console.error('Errore nel recupero dell\'utente:', error);
    }
};


    function filtraChiavi(obj, chiaviDaTenere) {
        return Object.keys(obj)
        .filter(key => chiaviDaTenere.includes(key))
        .reduce((acc, key) => {
            acc[key] = obj[key];
            return acc;
        }, {});
      }


      const formatJsonToHtml = (json) => {
        const formattedJson = JSON.stringify(json, null, 2);
        return formattedJson.split('\n').map((line, index) => {
            const keyValueMatch = line.match(/"([^"]+)":\s*(.*)/);
            if (keyValueMatch) {
                return (
                    <div key={index} className="json-line">
                        <span className="json-key">{keyValueMatch[1]}:</span>
                        <span className="json-value">{keyValueMatch[2].replace(/"/g, '')}</span>
                    </div>
                );
            }
            return (
                <div key={index} className="json-line">
                    <span>{line.replace(/"/g, '')}</span>
                </div>
            );
        });
    };
    

    function formattaDataOra(dataISO) {
        const data = new Date(dataISO);
        
        const opzioniData = { year: 'numeric', month: 'long', day: 'numeric' };
        const opzioniOra = { hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
      
        const dataFormattata = data.toLocaleDateString('it-IT', opzioniData);
        const oraFormattata = data.toLocaleTimeString('it-IT', opzioniOra);
      
        return `${dataFormattata} alle ${oraFormattata}`;
      }

    const refundUser = async (paylog,refund_price) => {
        try {
            console.log('refundUser:', paylog, refund_price)
            const request = {
                paylog: paylog,
                payment_company: config.servizi[paylog.servizio].payment_company,
                refund_price: refund_price
            }
            const response = await fetch(`${process.env.REACT_APP_PAYMENT}/orders/${paylog.payment_system}/refund`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });
            const data = await response.json();
    
            return data
           
        } catch (error) {
            console.error('Errore refundUser:', error);
        }
    };


    const aggiorna_navigation = async (e,navigation,clickid,token) => {
        const { name, value } = e.target;
        
        try {
            const response = await fetch(`${fetch_url}/updateNavigation/${clickid}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ [name]: value })
            });
            const data = await response.json();
            return data;

        } catch (error) {
            console.error('Errore nell\'aggiornamento della navigazione:', error);
        }
    };


  



    export default {
        fetchEmailLog,
        fetchPayLog,
        fetchChatLog,
        fetchUsers,
        formatJsonToHtml,
        refundUser,
        filtraChiavi,
        formattaDataOra,
        aggiorna_navigation,
        
    }