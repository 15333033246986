import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuthValue } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import functions from './functions/functions';
import '../../css/userDetail.css';
import  PaymentIcon  from './svg/payment_icon'
import Svg_modifica from './svg/modifica_utente';
import Svg_back from './svg/back';
import Svg_refund from './svg/refund';
import Filtri from './Filtri_dei_log'
import Privileged from '../privilegi_utenti/privilegi_utenti';
import PaymentLog from './PaymentLog';
import ChatLog from './ChatLog';

const UserDetail = () => {
    const { clickid } = useParams();
    const [user, setUser] = useState(null);
    const [log, setLog] = useState(null);
    
    const [payLog, setPayLog] = useState(null);
    const [payLogFiltered, set_filtered_payLog] = useState(null);
    
    
    const [chatLog, setChatLog] = useState(null); // Aggiungi stato per ChatLog
    const [chatLogFiltered, set_filtered_chatLog] = useState(null);
   

    const { token } = useAuthValue();
    const [expandedLogIndex, setExpandedLogIndex] = useState(false);
    const [expandedPayLogIndex, setExpandedPayLogIndex] = useState(false);
    const [expandedChatLogIndex, setExpandedChatLogIndex] = useState(false); // Aggiungi stato per ChatLog


    const [isEditing, setIsEditing] = useState(false);
   

    const navigate = useNavigate();

    const fetch_url = process.env.REACT_APP_CDN;

    useEffect(() => {
       

        functions.fetchUsers(clickid,token).then((data) => {
            setUser(data);
        })

    }, [clickid, token]);

    if (!user) {
        return <div>Loading...</div>;
    }

   


    const toggleLogVisibilityEmail = (index) => {
        if(index){
            functions.fetchEmailLog(clickid,token).then((data)=>{
                setLog(data)
                setExpandedLogIndex(expandedLogIndex === index ? false : index);
            })
        }
    };

    const toggleLogVisibilityPay = (index) => {
        if(index){
            functions.fetchPayLog(clickid,token).then((data)=>{
                set_filtered_payLog(data)
                setPayLog(data)
                
                setExpandedPayLogIndex(expandedPayLogIndex === index ? false : index);
            })
        }
    };

    const toggleLogVisibilityChat = (index) => {
        if(index){
            functions.fetchChatLog(clickid,token).then((data)=>{
                setExpandedChatLogIndex(expandedChatLogIndex === index ? false : index);
                set_filtered_chatLog(data)
                setChatLog(data)
                
            })
        }
    };

   



   
    function filtra_log (tipo_log,chiave,filter) {
      
        switch (tipo_log) {
            case 'payment':
                if(filter === "ALL"){
                    set_filtered_payLog(payLog)
                    return
                }
                const filteredPayLog = payLog.filter(oggetto => oggetto[chiave] === filter);
                console.log("INTERO", payLog);
                console.log("FILTRA", filteredPayLog);
                set_filtered_payLog([...filteredPayLog]); // Crea una copia dell'array filtrato
                break;
            case 'chargeId':
                if(filter == ""){
                    set_filtered_payLog(payLog)
                    return
                }
                var regex = new RegExp(filter, 'i');
                var newEl = payLog.filter(oggetto =>  (regex.test(oggetto[chiave])));
                set_filtered_payLog(newEl)
            case 'chat':
                if(filter == ""){
                    set_filtered_chatLog(chatLog)
                    return
                }
                var regex = new RegExp(filter, 'i');
                var newEl = chatLog.filter(oggetto =>  (regex.test(oggetto[chiave])));
                set_filtered_chatLog(newEl)
        }
        
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            navigation: {
                ...user.navigation,
                [name]: value
            }
        });
    };


    async function invia_update(tipo_update,e){
        await functions.aggiorna_navigation(e,user.navigation,clickid,token)
        functions.fetchUsers(clickid,token).then((data) => {
            setUser(data);
        })
        setIsEditing(false)
    }


    const handleEditClick = () => {
        setIsEditing(true);
    };


    return (
        <div className="user-detail-container">
            <div className='icon_container'>
                 <span  onClick={()=>{navigate(`/users`)}}> <Svg_back /></span>
                 <Privileged roles={['admin']}>
                 <span  onClick={handleEditClick}>
                    <Svg_modifica title="Modifica Utente" className="action-icon"  />
                </span>
                 </Privileged>
            </div>
            
            <div className="user-header">
            
                <h1>{user.user.email}</h1>
                
                {user.user.imageName && (
                        <img src={`${fetch_url}/uploads/${user.user.imageName}`} alt="User" className="user-image" />
                    )}
                 <div className="user-actions">
                   
                   
                   
                </div>
            </div>
            <div className="user_user_detail">
            <h2 style={{color:"#c250d2"}}>{user.user.premiumLevel > 0 ? "PREMIUM" : "FREE"}</h2>
                <p><strong>Clickid:</strong> <span className='valore'>{user.user.clickid}</span></p>
                <p><strong>Email:</strong> <span className='valore'>{user.user.email}</span></p>
                <p><strong>Servizio:</strong> <span className='valore'>{user.user.servizio}</span></p>
                <p><strong>Data di Creazione:</strong> <span className='valore'>{functions.formattaDataOra(user.user.createdDate)}</span></p>
                <p><strong>Last activity:</strong><span className='valore'> {functions.formattaDataOra(user.user.lastActivity)}</span></p>
                <div className="email-info">
                    <h2>Crediti</h2>
                    <p><strong>Crediti messaggi: </strong> 
                    {isEditing ? (
                        <form className='refund-form'>
                            <input
                                type="text"
                                name="creditiMessaggi"
                                value={user.navigation.creditiMessaggi}
                                onChange={handleInputChange}
                                onBlur={(e)=>{invia_update("navigation",e)}}
                            />
                          
                      
                        </form>
                    ) : (
                        <span className='valore'>{user.navigation.creditiMessaggi}</span>
                    )}
                    </p>
                    <p><strong>Crediti messaggi abbonamento:</strong> <span className='valore'>{user.navigation.creditiMessaggiAbbonamento}</span></p>
                    <p><strong>Crediti icebreaker:</strong><span className='valore'> {user.navigation.creditiIcebreaker}</span></p>
                    <p><strong>Crediti Like:</strong><span className='valore'> {user.navigation.creditiLike}</span></p>
                </div>
                <div className="email-info">
                    <h2>Email Info</h2>
                    <p><strong>Email Score:</strong><span className='valore'> {user.user.email_score}</span></p>
                    <p><strong>Attempt:</strong> <span className='valore'> {user.user.attempt ? user.user.attempt : "-"}</span></p>
                    <p><strong>Prossimo invio mail:</strong> <span className='valore'> {user.user.nextSend ? functions.formattaDataOra(user.user.nextSend) : "-"}</span></p>
                    <p><strong>Log Mail:</strong></p>
                    <button onClick={() => toggleLogVisibilityEmail(true)}>
                        {expandedLogIndex === true ? 'Nascondi Log' : 'Mostra Log'}
                    </button>
                    {log && log.length > 0 && (
                        <ul>
                            {expandedLogIndex === true && (
                                <>
                                    {log.map((email, index) => (
                                        <li key={index}>
                                            <div className="log-details">
                                                <p><strong>Logs:</strong> <span style={{maxWidth:"100%"}}>{functions.formatJsonToHtml(email)}</span></p>
                                            </div>
                                        </li>
                                    ))}
                                </>
                            )}
                        </ul>
                    )}
                </div>
                <p><strong>ID Flusso:</strong><span className='valore'> {user.user.fluxId}</span></p>
                <p><strong>Campagna:</strong> <span className='valore'>{user.user.campid}</span></p>
                <p><strong>Paese:</strong><span className='valore'> {user.user.country}</span></p>
                <div className="email-info">
                    <h2>Payment Info</h2>
                    <p><strong>Pagamenti con successo:</strong><span className='valore'> {user.successCount}</span></p>
                    <p><strong>Pagamenti in ko:</strong><span className='valore'> {user.failedCount}</span></p>
                    <p><strong>Unsub date:</strong><span className='valore'> {user.data_unsub ? functions.formattaDataOra(user.data_unsub) : "-"}</span></p>
                    <button onClick={() => toggleLogVisibilityPay(true)}>
                        {expandedPayLogIndex === true ? 'Nascondi PayLog' : 'Mostra PayLog'}
                    </button>
                   

                    {payLogFiltered && payLogFiltered.length >= 0 && (
                        <ul>
                            {expandedPayLogIndex === true && (
                                <>
                                
                                <Filtri tipologia_filtro={"button"} items={["SUCCESS","FAILED","REFUND","SCHEDULED","ALL"]} onButtonClick={(el)=>{filtra_log("payment","transactionResult",el)}} onChangeFilter={(el)=>{}} />
                    
                    <Filtri tipologia_filtro={"input"} items={["chargeId"]} onChangeFilter={(el) => { filtra_log("chargeId", "chargeId", el) }} />
                                
                                    {payLogFiltered.map((el, index) => (
                                            <PaymentLog el={el} el_totale={payLog[index]} />
                                    ))}
                                </>
                            )}
                        </ul>
                    )}
                </div>
                <div className="chat-info">
                    <h2>Chat Info</h2>
                    <button onClick={() => toggleLogVisibilityChat(true)}>
                        {expandedChatLogIndex === true ? 'Nascondi ChatLog' : 'Mostra ChatLog'}
                    </button>
                    {chatLog && chatLog.length > 0 && (
                        <ul>
                            {expandedChatLogIndex === true && (
                                <>
                                    
                                    <Filtri tipologia_filtro={"input"} items={["screenName"]} onChangeFilter={(el) => { filtra_log("chat", "screenName", el) }} />

                                        {chatLogFiltered.map((el) => (
                                            <ChatLog el={el} expandedChatLogIndex={expandedChatLogIndex}  />
                                        ))}
                                </>
                            )}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserDetail;