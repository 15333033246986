import React from 'react';
import { useAuthValue } from '../../AuthContext';

const Privileged = ({ roles, children }) => {
    const { user } = useAuthValue();

    // Controlla se l'utente ha uno dei ruoli autorizzati
    const hasAccess = roles.some(role => user.roles.includes(role));

    // Mostra i figli solo se l'utente ha accesso
    return hasAccess ? children : null;
};

export default Privileged;