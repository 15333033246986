import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from '../../AuthContext';
import '../../css/users.css';
import Filters from './Filtri_utenti';
import Popup from '../popup/popup';
import Svg_delete from './svg/delete';
import { use } from 'react';

const Users = () => {
    const [users, setUsers] = useState([]);
    const [filters, setFilters] = useState({});
    const [tempFilters, setTempFilters] = useState({});
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [popup, setPopup] = useState(null);
    const navigate = useNavigate();
    const { token } = useAuthValue();
    const { customer, setCustomer } = useAuthValue();
    const filtersRef = useRef(null);

    const fetch_url = process.env.REACT_APP_BACKEND;

    const fetchUsers = useCallback(async () => {
        console.log("gilberto", filters, page);
        try {
            const response = await fetch(fetch_url + '/getAllUsers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ filters, page })
            });
            const data = await response.json();
            setUsers(data.users);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Errore nel recupero degli utenti:', error);
        }
    }, [filters, page, token]);

    useEffect(() => {
        console.log("customer",customer)
        if(typeof obj === 'object'){
            console.log("secondo check",Object.keys(customer).length == 0)
            if((Object.keys(customer).length == 0) || !customer ){
                fetchUsers();
            }
        }else{
            fetchUsers();
        }
        
    }, []);

   

    useEffect(() => {
       
        if (customer) {
            const { dateRange, ...filtersWithoutDateRange } = customer;
            setFilters(filtersWithoutDateRange);
            setTempFilters(customer);
        }
    }, [customer]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filtersRef.current && !filtersRef.current.contains(event.target)) {
                setIsFiltersOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [filtersRef]);

    const handleRowClick = (clickid) => {

        setCustomer(tempFilters);
        navigate(`/user/${clickid}`);
    };

    const handleTempFilterChange = (e) => {
        
        setCustomer(null)
        setTempFilters({
            ...tempFilters,
            [e.target.name]: e.target.value != "" ? e.target.value : null 
        });
    };

    const handleDateRangeChange = (e) => {
        const value = e.target.value;
        const today = new Date();
        let startDate, endDate;

        switch (value) {
            case 'oggi':
                startDate = endDate = today;
                break;
            case 'ieri':
                startDate = endDate = new Date(today.setDate(today.getDate() - 1));
                break;
            case 'ultimi3giorni':
                startDate = new Date(today.setDate(today.getDate() - 3));
                endDate = new Date();
                break;
            case 'ultimi7giorni':
                startDate = new Date(today.setDate(today.getDate() - 7));
                endDate = new Date();
                break;
            case 'questomese':
                startDate = new Date(today.getFullYear(), today.getMonth(), 1);
                endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                break;
            case 'mesesuccessivo':
                startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                endDate = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case 'custom':
                startDate = tempFilters.startDate ? new Date(tempFilters.startDate) : '';
                endDate = tempFilters.endDate ? new Date(tempFilters.endDate) : '';
                break;
            default:
                startDate = endDate = '';
        }

        if (endDate) {
            endDate.setHours(23, 59, 59, 999); // Imposta l'ora della data di fine a 23:59:59
        }

        setTempFilters({
            ...tempFilters,
            dateRange: value,
            startDate: startDate ? startDate.toISOString().split('T')[0] : '',
            endDate: endDate ? endDate.toISOString().split('T')[0] : ''
        });
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const toggleFilters = () => {
        setIsFiltersOpen(!isFiltersOpen);
    };

    const resetFilters = () => {
        setFilters({});
        setTempFilters({});
        setCustomer(null);
        fetchUsers();
    };

    const handleDeleteUser = async (clickid) => {
        try {
            await fetch(fetch_url + `/deleteUser/${clickid}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(res => res.json())
                .then(res => {
                    if (res.message !== "ok") {
                        setPopup({
                            message: res.message,
                            buttons: [{ label: 'OK', action: () => setPopup(null) }]
                        });
                    }

                    fetchUsers(); // Ricarica la lista degli utenti dopo l'eliminazione
                });

        } catch (error) {
            console.error('Errore nell\'eliminazione dell\'utente:', error);

        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { dateRange, ...filtersWithoutDateRange } = tempFilters;
        setFilters(filtersWithoutDateRange)
        fetchUsers()
    };

    function formatDateWithLeadingZeros(date) {
        return new Date(date).toLocaleDateString('it-IT', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    function calcolaPremiumLevel(user){

        if(user.dataTransazione){
            return ""
        }

        if(user.premiumLevel == 0){
            return "FREE"
        }else{
            return "PREMIUM"
        }

    }

    return (
        <div className="users-container">
            <h1>Lista Utenti</h1>
            <button className="open-filters-btn" onClick={toggleFilters}>Filtri</button>
            <div ref={filtersRef}>
                <Filters filters={filters} handleFilterChange={handleTempFilterChange} isOpen={isFiltersOpen} toggleFilters={toggleFilters} />
            </div>

            <form className="speedy_filters" onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="premiumLevel">Livello Premium:</label>
                    <select name="premiumLevel" id="premiumLevel" value={tempFilters.premiumLevel || ''} onChange={handleTempFilterChange}>
                        <option value="">Tutti</option>
                        <option value="0">Free</option>
                        <option value="1">Premium</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="email">Email:</label>
                    <input type="text" name="email" id="email" value={tempFilters.email || ''} onChange={handleTempFilterChange} />
                </div>
                <div>
                    <label htmlFor="last4">Last 4:</label>
                    <input type="text" name="last4" id="last4" value={tempFilters.last4 || ''} onChange={handleTempFilterChange} />
                </div>
                <div>
                    <label htmlFor="first6">First 6:</label>
                    <input type="text" name="first6" id="first6" value={tempFilters.first6 || ''} onChange={handleTempFilterChange} />
                </div>
                <div>
                    <label htmlFor="last4">Clickid:</label>
                    <input type="clickid" name="clickid" id="Clickid" value={tempFilters.clickid || ''} onChange={handleTempFilterChange} />
                </div>
                <div>
                    <label htmlFor="dateRange">Data:</label>
                    <select name="dateRange" id="dateRange" value={tempFilters.dateRange || ''} onChange={handleDateRangeChange}>
                        <option value="">Seleziona</option>
                        <option value="oggi">Oggi</option>
                        <option value="ieri">Ieri</option>
                        <option value="ultimi3giorni">Ultimi 3 giorni</option>
                        <option value="ultimi7giorni">Ultimi 7 giorni</option>
                        <option value="questomese">Questo mese</option>
                        <option value="mesesuccessivo">Mese scorso</option>
                        <option value="custom">Custom</option>
                    </select>
                </div>
   
                {tempFilters.dateRange === 'custom' && (
                    <>
                        <div>
                            <label htmlFor="startDate">Da:</label>
                            <input type="date" name="startDate" id="startDate" value={tempFilters.startDate || ''} onChange={handleTempFilterChange} />
                        </div>
                        <div>
                            <label htmlFor="endDate">A:</label>
                            <input type="date" name="endDate" id="endDate" value={tempFilters.endDate || ''} onChange={handleTempFilterChange} />
                        </div>
                    </>
                )}
                <div>
                    <label htmlFor="submit_filters">Applica:</label>
                    <button  id="submit_filters" type="submit">Applica Filtri</button>
                </div>
                <div>
                    <label htmlFor="reset_filters">Reset:</label>
                    <button className="reset-filters-btn" id="reset_filters" onClick={resetFilters}>Reset Filtri</button>
                </div>
                
            </form>

            <table>
                <thead>
                    <tr>
                        <th>Clickid</th>
                        <th>Email</th>
                        <th>Data</th>
                        <th>Fonte</th>
                        <th>Ultima Attività</th>
                        <th>Livello Premium</th>
                        <th>Servizio</th>
                        <th>Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr className='userOnTable' key={user._id} >
                            <td onClick={() => handleRowClick(user.clickid)}>{user.clickid}</td>
                            <td onClick={() => handleRowClick(user.clickid)}>{user.email}</td>
                            <td onClick={() => handleRowClick(user.clickid)}>{formatDateWithLeadingZeros(user.createdDate ? user.createdDate : user.dataTransazione)}</td>
                            <td onClick={() => handleRowClick(user.clickid)}>{user.dataTransazione ? "Pay Log" : "User"}</td>
                            <td onClick={() => handleRowClick(user.clickid)}>{user.lastActivity}</td>
                            <td onClick={() => handleRowClick(user.clickid)}>{calcolaPremiumLevel(user)}</td>
                            <td onClick={() => handleRowClick(user.clickid)}>{user.servizio}</td>
                            <td>
                                <Svg_delete onClick={() => handleDeleteUser(user.clickid)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination">
                {Array.from({ length: Math.min(10, totalPages - (Math.floor((page - 1) / 10) * 10)) }, (_, index) => {
                    const pageIndex = Math.floor((page - 1) / 10) * 10 + index + 1;
                    return (
                        <button
                            key={pageIndex}
                            className={pageIndex === page ? 'active' : ''}
                            onClick={() => handlePageChange(pageIndex)}
                        >
                            {pageIndex}
                        </button>
                    );
                })}
                {totalPages > 10 && (
                    <>
                        {page > 10 && (
                            <button onClick={() => handlePageChange(Math.floor((page - 1) / 10) * 10)}>
                                &lt; Prev
                            </button>
                        )}
                        {page <= totalPages - 10 && (
                            <button onClick={() => handlePageChange(Math.floor((page - 1) / 10) * 10 + 11)}>
                                Next &gt;
                            </button>
                        )}
                    </>
                )}
            </div>
            {popup && <Popup message={popup.message} buttons={popup.buttons} />}
        </div>
    );
};

export default Users;