import React, { useState } from 'react';
import PaymentIcon from './svg/payment_icon';
import functions from './functions/functions';
import '../../css/paymentLog.css'; // Importa il file CSS

const PaymentLogDetails = ({ el, el_totale }) => {
    const [showRefundInput, setShowRefundInput] = useState(false);
    const [refundAmount, setRefundAmount] = useState('');
    const [refundMessage, setRefundMessage] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false); // Stato per la visibilità del contenuto

    const handleRefundClick = () => {
        setShowRefundInput(true);
    };

    const handleRefundSubmit = (e) => {
        e.preventDefault();
        functions.refundUser(el_totale, refundAmount).then((res) => {
            if (res.response === "[refund-received]") {
                setShowRefundInput(false);
                setRefundMessage('Refund richiesto con successo');
            } else {
                setRefundMessage('Errore durante la richiesta del refund');
            }
        });
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const refunded = el_totale?.additional_info?.status.split("_")[0] === "REFUNDED" ? el_totale?.additional_info?.status.split("_")[1] : false;
    console.log(refunded);

    const chiaviDaTenere = ['dataTransazione', 'prezzo', 'clickid', 'chargeId', 'first6', 'last4', 'email', 'transactionResult', 'failureCode', 'failureMessage', 'failureIssuerDeclineCode', 'servizio', 'descriptor', 'payment_system', 'saldo_crediti'];
    const filteredJson = functions.filtraChiavi(el, chiaviDaTenere);

    return (
        <div style={{ cursor: 'pointer'}} className={"log-details " + el.transactionResult} >
            <div className='payment_header'>
                <p>
                    <h3 onClick={toggleExpand} style={{ cursor: 'pointer' }}>
                        {functions.formattaDataOra(el.dataTransazione)}
                        <span style={{ marginLeft: '10px', display:"inline" }}>{isExpanded ? '▲' : '▼'}</span>
                    </h3>
                    {isExpanded && (
                        <>
                            <span style={{ maxWidth: "100%" }}>{functions.formatJsonToHtml(filteredJson)}</span>
                            <div className='payment_actions'>
                                {el.transactionResult === "SUCCESS" && (
                                    <>
                                        {!refunded ? <a onClick={handleRefundClick} className='action_button'>Refund</a> : <span className='refunded_label'>Refunded - {refunded}</span>}
                                        <span style={{ color: "red", marginLeft: "10px" }}>{refundMessage}</span>
                                        {showRefundInput && (
                                            <form onSubmit={handleRefundSubmit} className='refund-form'>
                                                <input
                                                    type="text"
                                                    value={refundAmount}
                                                    onChange={(e) => setRefundAmount(e.target.value)}
                                                    placeholder="Refund Amount"
                                                    required
                                                />
                                                <a onClick={handleRefundSubmit} className='action_button'>Submit</a>
                                            </form>
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </p>
                <PaymentIcon type={el.payment_system} />
            </div>
        </div>
    );
};

export default PaymentLogDetails;