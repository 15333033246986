import React from 'react';

const Filters = React.memo(({ filters, handleFilterChange, isOpen, toggleFilters }) => {
    return (
        <div className={`filters ${isOpen ? 'open' : ''}`}>
            <button className="close-btn" onClick={toggleFilters}>X</button>
            <input name="firstName" placeholder="Nome" onChange={handleFilterChange} />
            <input name="lastName" placeholder="Cognome" onChange={handleFilterChange} />
            <input name="email" placeholder="Email" onChange={handleFilterChange} />
            <input name="username" placeholder="Username" onChange={handleFilterChange} />
            <input name="city" placeholder="Città" onChange={handleFilterChange} />
            <input name="region" placeholder="Regione" onChange={handleFilterChange} />
            <input name="provincia" placeholder="Provincia" onChange={handleFilterChange} />
            <input name="telefono" placeholder="Telefono" onChange={handleFilterChange} />
            <input name="offer_id" placeholder="ID Offerta" onChange={handleFilterChange} />
            <input name="source" placeholder="Fonte" onChange={handleFilterChange} />
            <input name="campid" placeholder="Campagna" onChange={handleFilterChange} />
            <input name="country" placeholder="Paese" onChange={handleFilterChange} />
            <input
                type="date"
                name="data_visita"
                value={filters.data_visita || ''}
                onChange={handleFilterChange}
                placeholder="Data Visita"
            />
            <input name="servizio" placeholder="Servizio" onChange={handleFilterChange} />
            <input name="fluxId" placeholder="ID Flusso" onChange={handleFilterChange} />
            <input name="customerId" placeholder="ID Cliente" onChange={handleFilterChange} />
            <input name="sessionId" placeholder="ID Sessione" onChange={handleFilterChange} />
            <input name="campaignId" placeholder="ID Campagna" onChange={handleFilterChange} />
            <input name="remarketing_clickid" placeholder="ID Click Remarketing" onChange={handleFilterChange} />
        </div>
    );
});

export default Filters;