import React from 'react';
import '../../css/filtri_log.css';


function ItemFilter({ items, onButtonClick, tipologia_filtro, onChangeFilter }) {
    switch (tipologia_filtro) {
        case 'input':
            return ( <div className="ItemFilter">
                <div className="button-container">
                  {items.map((item, index) => (
                    <input
                      key={index}
                      className="rounded-button"
                      onChange={(el) => onChangeFilter(el.currentTarget.value)} // Passa l'elemento al componente padre
                      type='text'
                      placeholder={"Filtra per " + items[0]}
                    />
                  ))}
                </div>
              </div>)
        case 'button':
            return (
                <div className="ItemFilter">
                <div className="button-container">
                    {items.map((item, index) => (
                    <button
                        key={index}
                        className="rounded-button"
                        onClick={() => onButtonClick(item)} // Passa l'elemento al componente padre
                    >
                        {item}
                    </button>
                    ))}
                </div>
                </div>
            );
    }
}

export default ItemFilter;
