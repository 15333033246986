import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthValue } from './AuthContext';

const PrivateRoute = ({ children }) => {
  const { token } = useAuthValue();

  return token ? children : <Navigate to='/login' />;
};

export default PrivateRoute;