import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthValue } from './AuthContext';
import functions from './';
import './forms.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { setToken, setUser } = useAuthValue();
  const fetch_url = process.env.REACT_APP_BACKEND;
  const navigate = useNavigate();

  const login = async (e) => {
    e.preventDefault();

    try {
      const res = await loginToBackend();
      if (res.token) {
        setToken(res.token);
        localStorage.setItem('token', res.token);
        setUser({ roles: res.role });
        navigate('/users');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  
  const verifyToken = async (token) => {
    try {

        const response = await fetch(`${fetch_url}/verificaToken`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ token
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Errore nella verifica del token:', error);
    }
};

  async function loginToBackend() {
    const response = await fetch(`${fetch_url}/loginUser`, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    return response.json();
  }

    useEffect(() => {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
          verifyToken(storedToken).then((data) => {
              if (data && data.result) {
                  navigate('/users');
              }
          });
      }
  }, []);



  return (
    <div className='center'>
      <div className='auth'>
        <h1>Log in</h1>
        {error && <div className='auth__error'>{error}</div>}
        <form onSubmit={login} name='login_form'>
          <input
            type='email'
            value={email}
            required
            placeholder='Enter your email'
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type='password'
            value={password}
            required
            placeholder='Enter your password'
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type='submit'>Login</button>
        </form>
      </div>
    </div>
  );
}

export default Login;